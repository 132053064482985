<template>
  <b-sidebar
    v-model="state"
    visible
    width="400px"
    class="filter-wrapper"
  >
    <template #header>
      <div class="d-inline-flex align-items-center justify-content-between w-100">
        <div>
          <font-awesome-icon
            :icon="['fas', 'filter']"
            class="mr-1"
            size="sm"
          />
          {{ $t('eDoc_form_a_filter') }}
        </div>
        <b-link
          class="text-secondary"
          @click="() => { toggleState(); }"
        >
          <font-awesome-icon
            :icon="['fas', 'xmark']"
          />
        </b-link>
      </div>
    </template>
    <template #footer>
      <b-row ref="footer" class="my-2 mx-1">
        <b-col class="d-inline-flex justify-content-end">
          <b-btn class="ml-2" size="sm" @click="() => { onClear(); toggleState(); }">
            {{ $t('eDt_btnClear') }}
          </b-btn>
          <b-btn variant="primary" class="ml-2" size="sm" @click="() => { onSubmit(); }">
            {{ $t('eDt_btnSubmit') }}
          </b-btn>
        </b-col>
      </b-row>
    </template>
    <client-only>
      <div
        :class="isMobile ? '' : ''"
        :style="isMobile ? `height: ${bodyScrollHeight}; overflow: hidden; overflow-y: auto; ` : `height: ${height}px;`"
      >
        <b-row
          class="w-100 m-0 p-0"
        >
          <b-col
            v-for="field in fields.filter(i => !i.noCollapse)"
            :key="field.key"
            :cols="12"
            :class="field && field.class || ''"
            class=""
          >
            <b-form-group
              :id="field.key"
              :label="field.label"
              :label-for="`filter-input-${field.key}`"
              label-size="sm"
            >
              <b-form-select
                v-if="isSelectField(field)"
                :id="`filter-input-${field.key}`"
                v-model="rawFilter[field.key]"
                :options="filterData[field.key]"
                label-field="value"
                value-field="id"
                size="sm"
                :class="getInputCls(field.key)"
              />
              <b-form-datepicker
                v-else-if="isDateField(field)"
                :id="`filter-input-${field.key}`"
                v-model="rawFilter[field.key]"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                locale="en-GB"
                :class="getInputCls(field.key)"
              />
              <date-range
                v-else-if="isDateRangeField(field)"
                :id="`filter-input-${field.key}`"
                v-model="rawFilter[`${field.key}`]"
                :variant="getInputVariant(field.key)"
              />
              <num-range
                v-else-if="isNumRangeField(field)"
                :id="`filter-input-${field.key}`"
                v-model="rawFilter[`${field.key}`]"
                :variant="getInputVariant(field.key)"
              />
              <b-form-checkbox
                v-else-if="isCheckBoxField(field)"
                :id="`filter-checkbox-${field.key}`"
                v-model="rawFilter[field.key]"
                :class="getInputCls(field.key)"
              />
              <auto-complete
                v-else-if="isAutocompleteField(field)"
                :id="`filter-autocomplete-${field.key}`"
                v-model="rawFilter[field.key]"
                :type="field.key"
                :multiple="false"
                idx="0"
                size="sm"
                placeholder="Select city"
                style="width:100%"
                @input="() => {
                  let value = rawFilter[field.key]
                  if (value.length) {
                    value = value[0]
                  }
                  rawFilter[field.key] = value
                }"
              />
              <b-form-input
                v-else
                :id="`filter-input-${field.key}`"
                v-model="rawFilter[field.key]"
                trim
                size="sm"
                :class="getInputCls(field.key)"
                @keyup.enter="() => { onSubmit(); }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </client-only>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import fields from '~/mixins/fields'

export default {
  name: 'DataFilterVertical',
  props: {
    value: [Boolean],
    fields: {
      type: Array,
      default: () => ([])
    },
    filter: {
      type: Array,
      default: () => ([])
    },
    filterData: {
      type: Object,
      default: () => ({})
    },
    badgesOnly: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      state: this.value,
      rawFilter: {},
      bodyScrollHeight: '500px'
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    dataFields () {
      return this.fields && this.fields.filter((field) => {
        return field && field.filter
      })
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    },
    noCollapseCount () {
      return this.fields?.filter(i => !!i.noCollapse).length
    },
    showBadges () {
      if (this.isMobile) {
        return !this.state && (this.filter.filter(i => !i.permanent).length > 0) //
      } else {
        return (this.filter.filter(i => !i.permanent).length > 0)
      }
    },
    filterDataState () {
      return Object.keys(this.filterData).join('|') || ''
    }
  },
  watch: {
    value (n) {
      this.state = !!n
      this.$nextTick(() => {
        this.calcHeight() //
      })
    },
    state (n) {
      this.$emit('input', n)
    },
    filter: {
      handler (n) {
        this.setRawFilter()
      },
      deep: true,
      immediate: true
    },
    filterDataState (n) {
      if (n !== '') {
        this.initFilterValues()
      }
    }
  },
  mounted () {
    this.setRawFilter()
    this.calcHeight()
  },
  methods: {
    ...fields.methods,
    toggleState () {
      this.state = !this.state
      this.$emit('input', this.state)
    },
    setRawFilter () {
      this.rawFilter = {}
      this.filter.forEach((item) => {
        this.rawFilter[item.column] = item.value
      })
      this.$nextTick(() => {
        Object.keys(this.rawFilter)
          .forEach((k) => {
            if (!this.rawFilter[k]) {
              delete this.rawFilter[k]
            }
          })
        this.initFilterValues()
      })
    },
    getItemLabel (item) {
      let label = ''
      if (item && item.column) {
        label = item.column
        const field = this.fields.find(field => field.key === item.column)
        if (field) {
          label = field.label
        }
      }
      return label
    },
    // getItemValue (item) {
    //   let value = item.value
    //   if (item && item.column) {
    //     const field = this.fields.find(field => field.key === item.column)
    //     if (field) {
    //       if (this.isSelectField(field)) {
    //         const option = this.filterData[item.column].find(option => option.id === item.value)
    //         if (option) {
    //           value = option.text
    //         }
    //       } else if (this.isAutocompleteField(field)) {
    //         value = item.value.length ? item.value[0] : item.value
    //       } else if (this.isDateRangeField(field)) {
    //         const keys = Object.keys(item.value)
    //         if (keys.length === 2) {
    //           value = this.$t('eDt_f_dateRange_between', item.value)
    //         } else if (keys.includes('from')) {
    //           value = this.$t('eDt_f_dateRange_after', item.value)
    //         } else if (keys.includes('to')) {
    //           value = this.$t('eDt_f_dateRange_before', item.value)
    //         }
    //       } else if (this.isNumRangeField(field)) {
    //         const keys = Object.keys(item.value)
    //         if (keys.length === 2) {
    //           value = this.$t('eDt_f_numRange_between', item.value)
    //         } else if (keys.includes('from')) {
    //           value = this.$t('eDt_f_numRange_after', item.value)
    //         } else if (keys.includes('to')) {
    //           value = this.$t('eDt_f_numRange_before', item.value)
    //         }
    //       }
    //     }
    //   }
    //   return value
    // },
    onClearItem (item) {
      const filter = {}
      Object.keys(this.rawFilter)
        .filter(fieldName => fieldName !== item.column)
        .forEach((fieldName) => {
          filter[fieldName] = this.rawFilter[fieldName]
        })
      this.rawFilter = filter
      this.onSubmit()
    },
    onClear () {
      Object.keys(this.rawFilter).forEach((fieldKey) => {
        const initial = this.filter.find(i => i.column === fieldKey)
        if (!(initial && initial.permanent)) {
          this.rawFilter[fieldKey] = null
        }
      })
      this.$emit('submit', this.filter.filter(i => i.permanent))
    },
    onSubmit () {
      const data = []
      Object.keys(this.rawFilter)
        .filter((fieldKey) => {
          return !!this.rawFilter[fieldKey]
        })
        .forEach((fieldKey) => {
          const initial = this.filter.find(i => i.column === fieldKey)
          data.push({
            column: fieldKey,
            value: this.rawFilter[fieldKey],
            permanent: !!(initial && initial.permanent)
          })
        })
      this.$emit('submit', data)
    },
    onShown (e) {
      this.$emit('on-shown', e)
    },
    onHidden (e) {
      this.$emit('on-hidden', e)
    },
    calcHeight () {
      this.bodyScrollHeight = '0'
      if (this.$refs.filter && window) {
        const $navbar = document.querySelector('.navbar')
        const $filter = this.$refs?.filter
        const windowHeight = window.innerHeight
        const navbarHeight = $navbar?.clientHeight || 0
        const topBarHeight = this.$parent?.$refs?.topBar?.offsetHeight || 0
        const cardHeaderHeight = $filter?.querySelector('.card-header')?.offsetHeight || 0
        const cardFooterHeight = $filter?.querySelector('.card-footer')?.offsetHeight || 0
        const cardBodyGaps = 16

        const height = windowHeight - navbarHeight - topBarHeight - cardHeaderHeight - cardFooterHeight - cardBodyGaps - 16
        this.bodyScrollHeight = `${height}px`

        // console.log('bodyScrollHeight', this.bodyScrollHeight, {
        //   windowHeight,
        //   navbarHeight,
        //   topBarHeight,
        //   cardHeaderHeight,
        //   cardFooterHeight
        // })

        // let subHeight = 0
        // if (footer) {
        //   subHeight = 198 + footer.offsetHeight
        // } else {
        //   subHeight = 198
        // }
        // this.bodyScrollHeight = `calc(100svh - ${subHeight}px);`
        // settings hardcode
        if (this.$route.path.match(/settings/)) {
          this.bodyScrollHeight = '260px'
        }
      }
    },
    getInputCls (key) {
      if (Object.keys(this.rawFilter).includes(key)) {
        return 'border-info text-info'
      }
      return 'border-muted text-muted'
    },
    getInputVariant (key) {
      if (Object.keys(this.rawFilter).includes(key)) {
        return 'info'
      }
      return 'muted'
    },
    isNoCollapseField (key) {
      return this.fields
        .filter(i => i.noCollapse)
        .map(i => i.key)
        .includes(key)
    },
    // update filter select values to correct display
    initFilterValues () {
      const filteredColumnNames = this.filter?.map(i => i.column) || []
      const emptySelectFields = this.fields
        ?.filter(i => i.type === 'select' && !filteredColumnNames.includes(i.key) && this.filterData[i.key]?.length > 0 && this.filterData[i.key][0].id === '')
        ?.map(i => i.key) || []
      emptySelectFields.forEach((key) => {
        this.rawFilter[key] = ''
      })
    }
  }
}
</script>

<style lang="scss">
.filter-wrapper {
  .b-form-datepicker {
    &.text-info {
      .btn, .form-control  {
        color: var(--info) !important;
      }
    }
  }
}
.b-sidebar-outer {
  position: relative;
  //display: contents;
  .b-sidebar:not(.b-sidebar-right) {
    left: auto;
  }
  .b-sidebar {
    //position: absolute;
    //height: 100%;
    z-index: 1030;
  }
}
</style>
